import React, { Component } from "react";
import PropTypes from "prop-types";
import partial from "lodash/partial";
import { Link, browserHistory } from "react-router";
import classnames from "classnames";
import FaqList from "common/components/faqs";

const SUB_CATEGORIES = [
  {
    slug: "packaging-design",
    title: "Packaging Design",
    intro:
      "You need a custom box, bottle, or other type of container to hold and present your product.",
    bullets: [
      "Your products will stand out with unique packaging",
      "Deliver a memorable unboxing and user experience",
      "Customized for your product",
    ],
    faqs: [
      {
        question: "What types of packages can you get designed?",
        answer:
          "<p>You can get any type of packaging designed. Our designers have created thousands of unique packaging designs for bottles, packets, bags, boxes, canisters, shipping containers, and more.</p><p>We continue to be amazed with the breadth of the packaging design projects our community has done. Many of these projects are protected by NDAs so we can't publicly talk about them or show you the work from those projects, but packaging design projects have included the design of packaging for nearly every industry, using many different materials, and for Fortune 100 companies as well as many small businesses around the world.</p>",
      },
      {
        question: "Do you acquire full rights to the design you buy?",
        answer:
          "<p>Yes! Every project on crowdspring is protected by a customized legal agreement that transfers to the client full rights to the packaging design, once you approve the final files and we pay the designer.</p>",
      },
      {
        question: "Why does crowdspring offer a 100% money-back guarantee?",
        answer:
          "<p>Crowdspring guarantees your satisfaction in packaging design projects. There's NO fine-print. We are not happy until you are. People are so thrilled with the designs they receive on crowdspring that we are proud to stand behind the work and our design process and make you an unconditional promise.</p>",
      },
    ],
    btnLabel: "I need custom packaging design",
    images: [
      "https://assets.crowdspring.com/marketing/cup-for-italy_package-design_crowdspring.jpg",
      "https://assets.crowdspring.com/marketing/package+design+2.png",
      "https://assets.crowdspring.com/marketing/bottle-design.jpg",
      "https://assets.crowdspring.com/marketing/package.jpg",
    ],
  },
  {
    slug: "package-graphics",
    inverted: true,
    title: "Package Graphics",
    intro:
      "You already have packaging but need custom graphics or labels designed for that packaging.",
    bullets: [
      "Custom, unique labels and graphics",
      "Create a strong visual identity for products",
      "Share your brand story, connect with customers",
    ],
    faqs: [
      {
        question: "What is package graphics design?",
        answer:
          "<p>Package graphics design is the discipline of creating the graphics and visible outer design of a product. This container might be a simple bottle with a label or an elaborately designed box.</p><p>Creating something inviting and beautiful for your customers to pick up and bring home with them requires a great deal of skill. Don't let yourself get boxed in with poor design. Let crowdspring turn your packages into beautifully designed creations your customers will love to hold on to.</p>",
      },
      {
        question: "Do you acquire full rights to the design you buy?",
        answer:
          "<p>Yes! Every project on crowdspring is protected by a customized legal agreement that transfers to the client full rights to the package graphics design, once you approve the final files and we pay the designer.</p>",
      },
      {
        question: "Why does crowdspring offer a 100% money-back guarantee?",
        answer:
          "<p>Crowdspring guarantees your satisfaction in package graphics design projects. There's NO fine-print. We are not happy until you are. People are so thrilled with the designs they receive on crowdspring that we are proud to stand behind the work and make you an unconditional promise.</p>",
      },
    ],
    btnLabel: "I need custom package graphics",
    images: [
      "https://assets.crowdspring.com/marketing/cellular-beauty_package-graphics_crowdspring.jpg",
      "https://assets.crowdspring.com/marketing/ishira_package-design_crowdspring.jpg",
      "https://assets.crowdspring.com/marketing/nutribab_package-graphics_crowdspring.jpg",
      "https://assets.crowdspring.com/marketing/package+graphics+2-cropped.png",
    ],
  },
];

export default class SelectPackageSubcat extends Component {
  constructor(props) {
    super(props);

    this.goToPap = this.goToPap.bind(this);
    this.renderSubcat = this.renderSubcat.bind(this);
  }

  goToPap(slug) {
    const { replace, location, onLinkClick } = this.props;
    const pathname = `/start-a-project/product-packaging/${slug}/details/package/`;

    if (onLinkClick) {
      onLinkClick();
    }

    browserHistory[replace ? "replace" : "push"]({
      pathname,
      query: location.query.origin ? location.query : {},
      state: { packageSubcatSelected: true },
    });
  }

  renderSubcat({
    slug,
    inverted,
    btnLabel,
    title,
    intro,
    bullets,
    faqs,
    images,
  }) {
    return (
      <div key={slug} className="row align-middle sub-category">
        <div
          className={classnames("columns large-8 small-16", {
            "large-order-2": inverted,
          })}
        >
          <div className="row">
            {images.map((img, i) => (
              <div key={i} className="columns small-8">
                <img src={img} />
              </div>
            ))}
          </div>
        </div>
        <div
          className={classnames("columns large-8 small-16 content-column", {
            "large-order-1": inverted,
          })}
        >
          <h2 className="subcat-title">{title}</h2>
          <p className="intro">{intro}</p>
          <ul className="bullets">
            {bullets.map((b, i) => (
              <li key={i}>
                <i className="fa fa-long-arrow-right" /> {b}
              </li>
            ))}
          </ul>
          <h3>FAQs</h3>
          <FaqList data={faqs} />
          <button
            className="btn btn-primary"
            onClick={partial(this.goToPap, slug)}
          >
            {btnLabel}
          </button>
        </div>
      </div>
    );
  }

  render() {
    const { background, cta } = this.props;

    return (
      <div
        className={classnames("block block-select-package-subcat", background)}
      >
        <div className="row columns">
          <div className="block-header">
            <h2>Do you need packaging or graphics designed?</h2>
          </div>
        </div>

        {SUB_CATEGORIES.map(this.renderSubcat)}

        {!!cta && (
          <p className="text-center cta">
            <Link to={cta.link}>{cta.label}</Link>
          </p>
        )}
      </div>
    );
  }
}

SelectPackageSubcat.propTypes = {
  background: PropTypes.string,
  cta: PropTypes.object,
  replace: PropTypes.bool,
  onLinkClick: PropTypes.func,
};
