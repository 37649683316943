import request from "axios";
import idx from "idx";
import pick from "lodash/pick";
import { browserHistory } from "react-router";
import { ADMIN_ROOT } from "common/variables";
import { setBannerError } from "error";
import { showBanner, TYPE_SUCCESS } from "common/ducks/banner";
import { getAPIQueryString } from "common/utils";
import { savePageCount } from "common/pager/ducks";
import { URLS_RESOURCE_NAME, URL_GROUPS_RESOURCE_NAME } from "hive/constants";

/* ACTIONS
================================================================================================ */
const FETCH_URL = "hive/FETCH_URL";
const FETCH_URL_REQUEST = "hive/FETCH_URL_REQUEST";
const FETCH_URL_SUCCESS = "hive/FETCH_URL_SUCCESS";

const SAVE_URL = "hive/SAVE_URL";
const SAVE_URL_SUCCESS = "hive/SAVE_URL_SUCCESS";

const FETCH_ALL_URL_GROUPS = "hive/FETCH_ALL_URL_GROUPS";
const FETCH_ALL_URL_GROUPS_SUCCESS = "hive/FETCH_ALL_URL_GROUPS_SUCCESS";

const CHECK_URL = "hive/CHECK_URL";
const CHECK_URL_REQUEST = "hive/CHECK_URL_REQUEST";
const CHECK_URL_SUCCESS = "hive/CHECK_URL_SUCCESS";
const CHECK_URL_FAILURE = "hive/CHECK_URL_FAILURE";

const FETCH_URL_GROUP = "hive/FETCH_URL_GROUP";
const FETCH_URL_GROUP_REQUEST = "hive/FETCH_URL_GROUP_REQUEST";
const FETCH_URL_GROUP_SUCCESS = "hive/FETCH_URL_GROUP_SUCCESS";

const SAVE_URL_GROUP = "hive/SAVE_URL_GROUP";
const SAVE_URL_GROUP_SUCCESS = "hive/SAVE_URL_GROUP_SUCCESS";

const FETCH_URLS = "hive/FETCH_URLS";
const FETCH_URLS_REQUEST = "hive/FETCH_URLS_REQUEST";
const FETCH_URLS_SUCCESS = "hive/FETCH_URLS_SUCCESS";

const FETCH_URL_GROUPS = "hive/FETCH_URL_GROUPS";
const FETCH_URL_GROUPS_REQUEST = "hive/FETCH_URL_GROUPS_REQUEST";
const FETCH_URL_GROUPS_SUCCESS = "hive/FETCH_URL_GROUPS_SUCCESS";

/* HELPERS
================================================================================================ */
export const createUrlGroupRequest = () =>
  request.post("/api/v1/monitored_url_groups/");

export const checkAllUrlsRequest = () =>
  request.post("/api/v1/monitored_urls/check_all/");

/* INITIAL STATES
================================================================================================ */
const initialState = {
  url: null,
  checkingUrl: false,
  allUrlGroups: [],
  urlGroup: null,
  urls: null,
  urlCount: 0,
  urlGroups: null,
  urlGroupCount: 0,
};

/* REDUCERS
================================================================================================ */
export default function reducer(state = initialState, action) {
  switch (action.type) {
    case FETCH_URL_REQUEST:
      return {
        ...state,
        url: null,
      };
    case FETCH_URL_SUCCESS:
    case SAVE_URL_SUCCESS:
      return {
        ...state,
        url: action.payload,
      };
    case FETCH_ALL_URL_GROUPS_SUCCESS:
      return {
        ...state,
        allUrlGroups: action.payload,
      };
    case CHECK_URL_REQUEST:
      return {
        ...state,
        checkingUrl: true,
      };
    case CHECK_URL_SUCCESS:
      return {
        ...state,
        checkingUrl: false,
        url:
          idx(state.url, (_) => _.id) === action.payload.id
            ? {
                ...state.url,
                ...pick(
                  action.payload,
                  "ip_info",
                  "last_checked_at",
                  "last_check_errors"
                ),
              }
            : state.url,
      };
    case CHECK_URL_FAILURE:
      return {
        ...state,
        checkingUrl: false,
      };
    case FETCH_URL_GROUP_REQUEST:
      return {
        ...state,
        urlGroup: null,
      };
    case FETCH_URL_GROUP_SUCCESS:
    case SAVE_URL_GROUP_SUCCESS:
      return {
        ...state,
        urlGroup: action.payload,
      };
    case FETCH_URLS_REQUEST:
      return {
        ...state,
        urls: null,
      };
    case FETCH_URLS_SUCCESS:
      return {
        ...state,
        urls: action.payload.results,
        urlCount: action.payload.count,
      };
    case FETCH_URL_GROUPS_REQUEST:
      return {
        ...state,
        urlGroups: null,
      };
    case FETCH_URL_GROUPS_SUCCESS:
      return {
        ...state,
        urlGroups: action.payload.results,
        urlGroupCount: action.payload.count,
      };
    default:
      return state;
  }
}

/* ACTION CREATORS
 ================================================================================================ */
export const fetchAllUrlGroups = () => (dispatch) => {
  return dispatch({
    type: FETCH_ALL_URL_GROUPS,
    promise: request.get("/api/v1/monitored_url_groups/all/"),
  });
};

export const fetchUrl = (id) => (dispatch) => {
  const promise = request.get(`/api/v1/monitored_urls/${id}/`);

  promise.catch((err) => {
    if (err.status === 404) {
      browserHistory.replace("/404/");
    }
  });

  return dispatch({
    type: FETCH_URL,
    promise,
  });
};

export const createUrl = (data) => (dispatch) => {
  const promise = request.post("/api/v1/monitored_urls/", data);

  promise
    .then(() => {
      browserHistory.replace(`${ADMIN_ROOT}/hive/monitored-urls/`);
      dispatch(
        showBanner(
          "Success",
          "Monitored URL successfully added.",
          undefined,
          TYPE_SUCCESS,
          null,
          3000
        )
      );
    })
    .catch((err) => {
      const errorMsg = err.data
        ? JSON.stringify(err.data)
        : "Make sure the data you entered is correct.";
      dispatch(setBannerError("Error adding monitored URL to Hive", errorMsg));
    });

  return promise;
};

export const saveUrl = (id, data) => (dispatch) => {
  const promise = request.patch(`/api/v1/monitored_urls/${id}/`, data);

  promise
    .then(() => {
      dispatch(
        showBanner(
          "Success",
          "Monitored URL successfully saved.",
          undefined,
          TYPE_SUCCESS,
          null,
          3000
        )
      );
    })
    .catch((err) => {
      const errorMsg = err.data
        ? JSON.stringify(err.data)
        : "Make sure the data you entered is correct.";
      dispatch(setBannerError("Error saving monitored URL", errorMsg));
    });

  dispatch({
    type: SAVE_URL,
    promise,
  });

  return promise;
};

export const deleteUrl = (id) => (dispatch) => {
  const promise = request.delete(`/api/v1/monitored_urls/${id}/`);

  promise.catch((err) => {
    const errorMsg = err.data ? JSON.stringify(err.data) : "Unkown error.";
    dispatch(setBannerError("Error deleting monitored URL", errorMsg));
  });

  return promise;
};

export const checkUrl = (id) => (dispatch) => {
  const promise = request.post(`/api/v1/monitored_urls/${id}/check/`);

  promise
    .then(() => {
      dispatch(
        showBanner(
          "Success",
          "Monitored URL successfully checked.",
          undefined,
          TYPE_SUCCESS,
          null,
          3000
        )
      );
    })
    .catch(() => {
      dispatch(
        setBannerError("Error checking monitored URL", "Please try again.")
      );
    });

  dispatch({
    type: CHECK_URL,
    promise,
  });

  return promise;
};

export const fetchUrlGroup = (id) => (dispatch) => {
  const promise = request.get(`/api/v1/monitored_url_groups/${id}/`);

  promise.catch((err) => {
    if (err.status === 404) {
      browserHistory.replace("/404/");
    }
  });

  return dispatch({
    type: FETCH_URL_GROUP,
    promise,
  });
};

export const saveUrlGroup = (id, data) => (dispatch) => {
  const promise = request.patch(`/api/v1/monitored_url_groups/${id}/`, data);

  promise
    .then(() => {
      dispatch(
        showBanner(
          "Success",
          "Monitored URL group successfully saved.",
          undefined,
          TYPE_SUCCESS,
          null,
          3000
        )
      );
    })
    .catch((err) => {
      const errorMsg = err.data
        ? JSON.stringify(err.data)
        : "Make sure the data you entered is correct.";
      dispatch(setBannerError("Error saving monitored URL group", errorMsg));
    });

  dispatch({
    type: SAVE_URL_GROUP,
    promise,
  });

  return promise;
};

export const deleteUrlGroup = (id) => (dispatch) => {
  const promise = request.delete(`/api/v1/monitored_url_groups/${id}/`);

  promise.catch((err) => {
    const errorMsg = err.data ? JSON.stringify(err.data) : "Unkown error.";
    dispatch(setBannerError("Error deleting monitored URL group", errorMsg));
  });

  return promise;
};

export const fetchUrls = (query, groupId) => (dispatch) => {
  const extraAPIQuery = {
    groups: groupId ? [groupId] : undefined,
  };
  const queryStr = getAPIQueryString(URLS_RESOURCE_NAME, query, {
    extraAPIQuery,
  });
  const promise = request.get(`/api/v1/monitored_urls/?${queryStr}`);

  /* save page count in pager state */
  promise.then((res) => dispatch(savePageCount(URLS_RESOURCE_NAME, res)));

  return dispatch({
    type: FETCH_URLS,
    promise,
  });
};

export const fetchUrlGroups = (query) => (dispatch) => {
  const queryStr = getAPIQueryString(URL_GROUPS_RESOURCE_NAME, query);
  const promise = request.get(`/api/v1/monitored_url_groups/?${queryStr}`);

  /* save page count in pager state */
  promise.then((res) => dispatch(savePageCount(URL_GROUPS_RESOURCE_NAME, res)));

  return dispatch({
    type: FETCH_URL_GROUPS,
    promise,
  });
};
