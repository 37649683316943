import React from "react";
import idx from "idx";
import { PACKAGE_ITEMS } from "pap/ducks/workflow";
import { getSubCategory, getCategory } from "common/ducks/categories";
import { getStoredDiscountCode } from "pap/ducks/pricing";

export const getCurrentStepIndex = (stepList, stepName) => {
  if (!stepList) {
    return 0;
  }
  return stepList.findIndex((s) => s.name === stepName);
};

export const getDetailsStepLink = (
  categorySlug,
  subCategorySlug,
  stepList,
  index
) => {
  const step = stepList[index];
  if (!step) {
    return null;
  }
  return `/start-a-project/${categorySlug}/${subCategorySlug}/details/${step.name}`;
};

/* Get brief questions that should be visible for a given project. */
export const getVisibleBriefQuestions = (project, forPapBrief) => {
  if (!project || !project.id) {
    return [];
  }

  let excluded = [];

  /* never show public description for PAP brief steps, since it's set in a different PAP section */
  if (forPapBrief) {
    excluded = ["description"];
  }

  /* don't show public description if project doesn't require an NDA */
  if (!project.nda_required) {
    excluded = ["description"];
  }

  return project.brief_questions.filter((q) => !excluded.includes(q.name));
};

/* Get values for project fields that can be changed once features are activated. */
export const getFeatureProjectFields = (formValues, project) => {
  const fieldsData = {};
  let updatedBriefAnswers = idx(project, (_) => _.brief_answers) || {};

  PACKAGE_ITEMS.map((item) => {
    const stepValues = formValues[item.step] || {};
    (item.projectFields || []).forEach((field) => {
      const value = stepValues[field.name];
      /* just ignore if there are no changes */
      if (value === undefined) {
        return;
      }

      /* if brief question, update brief answers accordingly */
      if (field.type === "brief_question") {
        updatedBriefAnswers = { ...updatedBriefAnswers, [field.name]: value };
      } else {
        fieldsData[field.name] = value;
      }
    });
  });

  fieldsData["brief_answers"] = updatedBriefAnswers;

  return fieldsData;
};

/* Provide detailed project cost based on package features and user choices. */
export const getDetailedCost = (
  currentFeatures,
  currentAwards,
  formValues,
  awardValue1to1,
  billables,
  user,
  currentRankedTips,
  acceptEmptyFeatures,
  finalRoundCredit
) => {
  /* No current features: nothing to do */
  if (
    !currentFeatures ||
    (!acceptEmptyFeatures && currentFeatures.length == 0)
  ) {
    return { base: 0, total: 0, items: [] };
  }

  const includedAwards = currentAwards
    .filter(({ feature }) => feature.included)
    .map((a) => a.feature);

  const extraAwards = currentAwards.filter(
    ({ feature }) => !feature.included && feature.active
  );

  let referenceAward = currentAwards.find((a) => a.feature.position == 0);
  referenceAward = referenceAward ? referenceAward.feature : null;

  const feeFeature = currentFeatures.find((f) => f.feature.name == "Fee");
  const items = [];
  let total = 0;
  let fees = feeFeature
    ? parseFloat(feeFeature.feature.price) + parseFloat(feeFeature.feature.fee)
    : 0;

  /* Go over customizable package items and add their prices to the list */
  PACKAGE_ITEMS.map((item) => {
    const stepValues = formValues[item.step] || {};
    const fieldValues = stepValues[item.fieldName];

    /* included awards */
    if (item.fieldName == "included_awards") {
      includedAwards.map((a) => {
        const value = (fieldValues || {})[a.id] || a.value;
        const detailedCost = getDetailedAwardCost(a, value);
        fees = fees + detailedCost.fees;
        total = total + parseFloat(value);
        items.push({ name: "Award", price: value });
      });

      /* extra awards */
    } else if (item.fieldName == "extra_awards") {
      const values = fieldValues || [];

      /* extra awards already created */
      extraAwards.map((a) => {
        /* check if modified */
        const edited = values.find((v) => v.uuid == a.uuid);

        /* marked as deleted: ignore */
        if (edited && edited.deleted) {
          return;
        }
        const value = edited ? edited.value : a.feature.value;
        const detailedCost = getDetailedAwardCost(a.feature, value);
        fees = fees + detailedCost.fees;
        total = total + parseFloat(value);
        items.push({
          name: includedAwards.length > 0 ? "Extra Award" : "Award",
          price: value,
        });
      });

      /* extra awards still not submitted */
      values
        .filter((v) => !v.uuid && v.value)
        .map((v) => {
          /* as the award hasn't yet been created, we need to use the reference award to calculate
           its cost */
          const detailedCost = getDetailedAwardCost(referenceAward, v.value);
          fees = fees + detailedCost.fees;
          total = total + parseFloat(v.value);
          items.push({ name: "Extra Award", price: v.value });
        });

      /* feature group */
    } else if (item.isGroup) {
      const activeFeature = currentFeatures.find(
        ({ feature }) =>
          feature.group == item.name &&
          ((fieldValues ? feature.id == fieldValues : feature.active) ||
            isDiscountFeature({ feature }, currentFeatures, billables, user))
      );
      if (activeFeature) {
        const feature = activeFeature.feature;
        items.push({ name: feature.name, price: feature.price });
        total = total + parseFloat(feature.price);
      }

      /* single feature */
    } else {
      const activeFeature = currentFeatures.find(
        ({ feature }) =>
          feature.name == item.name &&
          (feature.active ||
            fieldValues ||
            isDiscountFeature({ feature }, currentFeatures, billables, user))
      );
      if (activeFeature) {
        const feature = activeFeature.feature;
        items.push({ name: feature.name, price: feature.price });
        total = total + parseFloat(feature.price);
      }
    }
  });

  /* add items related to standard tips */
  const standardTips =
    billables && billables.filter
      ? billables.filter((b) => b.source === "TIP")
      : [];
  standardTips.forEach((standardTip) => {
    total = total + parseFloat(standardTip.amount);
    fees = fees + parseFloat(standardTip.fee);
    items.push({
      name: "Standard Tip",
      price: standardTip.amount,
      fee: standardTip.fee,
    });
  });

  if (parseFloat(finalRoundCredit || 0)) {
    total = total + parseFloat(finalRoundCredit);
    items.push({ name: "Standard Tip", price: finalRoundCredit, fee: "0.00" });
  }

  /* add items related to ranked tips */
  const rankedTips = currentRankedTips || [];
  rankedTips.forEach((rankedTip) => {
    total = total + parseFloat(rankedTip.feature.value);
    items.push({ name: "Tip", price: rankedTip.feature.value });
  });

  /* during negotiation of 1-to-1 projects only, i.e. no award has been created yet,
     since its value is under negotiation.*/
  if (parseFloat(awardValue1to1) && extraAwards.length == 0) {
    const detailedCost = getDetailedAwardCost(referenceAward, awardValue1to1);
    fees = fees + detailedCost.fees;
    total = total + parseFloat(awardValue1to1);
    items.push({ name: "Award", price: awardValue1to1 });
  }

  if (fees > 0) {
    items.push({ name: "Fees", price: fees });
  }

  total = total + fees;

  /* account for discount codes */
  const discounts = getDiscounts(currentFeatures, total, fees, billables, user);

  return {
    total: total + discounts.total,
    items: items.concat(discounts.items),
  };
};

/* Calculate award fees based on fee rules and award value */
const getAwardFees = (fee, minFee, algorithm, rules, value) => {
  let fees = 0;

  if (parseFloat(fee)) {
    fees = parseFloat(fee);
  } else {
    for (let rule of rules) {
      const start = parseFloat(rule.start);
      const end = parseFloat(rule.end) || value;
      const price = parseFloat(rule.price);
      let increase = 0;

      /* not in range */
      if (value < start) {
        continue;
      }

      /* "ranged" fee algorithm */
      if (algorithm == "ranged") {
        /* flat fee */
        if (rule.price_calculation == "FLAT") {
          increase = price;
          /* percentage-based fee */
        } else if (rule.price_calculation == "PERCENTAGE") {
          increase = (end - start) * (price / 100);
        }

        fees = fees + increase;

        /* "first-match" fee algorithm */
      } else if (algorithm == "first match" && value <= end) {
        /* flat fee */
        if (rule.price_calculation == "FLAT") {
          fees = price;
          /* percentage-based fee */
        } else if (rule.price_calculation == "PERCENTAGE") {
          fees = value * (price / 100);
        }

        break;
      }
    }
  }

  return Math.max(fees, minFee);
};

/* Provide detailed award cost based on original award and current value. */
export const getDetailedAwardCost = (award, value) => {
  const originalValue = parseFloat(award.value);
  const originalFees = getAwardFees(
    award.fee,
    award.minimum_fee,
    award.fee_algorithm,
    award.fee_rules,
    originalValue
  );
  const originalTotal = originalValue + originalFees;
  const currentValue = parseFloat(value);
  const fees = getAwardFees(
    award.fee,
    award.minimum_fee,
    award.fee_algorithm,
    award.fee_rules,
    currentValue
  );
  const total = currentValue + fees;

  return {
    total: total,
    fees: fees,
    totalVariation: total - originalTotal,
  };
};

/* Get sub-category from project data */
export const getSubCategoryFromProject = (projectData, categories) => {
  if (!(projectData && categories.data.length > 0)) return undefined;
  return getSubCategory(categories, projectData.sub_category);
};

export const getCategoryFromProject = (projectData, categories) => {
  if (!(projectData && categories.data.length > 0)) return undefined;
  return getCategory(categories.data, projectData.category);
};

/*
  Get discount code from one of 2 sources:
  - user object - BEFORE PROJECT IS POSTED
  - project billables - AFTER PROJECT IS POSTED

  A discount code is composed of:
  - a monetary discount (can be a flat value or a percentage over project cost or fees),
  - optionally free features
*/
const getDiscountCode = (billables, user) => {
  const codeBillable =
    billables && billables.find ? billables.find((b) => b.is_discount) : null;
  return codeBillable ? codeBillable.details.code : getStoredDiscountCode(user);
};

/* Get discount items, along with their total.*/
const getDiscounts = (currentFeatures, totalCost, fees, billables, user) => {
  const code = getDiscountCode(billables, user);

  if (!code) {
    return { total: 0, items: [] };
  }

  let total = 0;
  let discount = 0;
  const items = [];

  /* flat */
  if (code.kind === "flat") {
    discount = parseFloat(code.amount);

    /* percentage... */
  } else {
    /* over total cost... */
    if (code.percentage_over === "project total") {
      discount = totalCost * (parseFloat(code.percentage) / 100);

      /* or over fees */
    } else {
      discount = fees * (parseFloat(code.percentage) / 100);
    }
  }
  discount *= -1;
  total += discount;
  items.push({ name: "Discount", price: discount, slug: code.slug });

  getDiscountFeatures(currentFeatures, billables, user).forEach((f) => {
    const price = -parseFloat(f.feature.price);
    items.push({ name: f.feature.name + " Discount", price });
    total += price;
  });

  return { total, items };
};

/* get features from a list that are part of a coupon code */
const getDiscountFeatures = (features, billables, user) => {
  const code = getDiscountCode(billables, user);

  if (!code || !code.free_features || code.free_features.length == 0) {
    return [];
  }

  return features.filter((f) =>
    code.free_features.includes(f.feature.common_name)
  );
};

/* check whether a feature is among the discount ones */
export const isDiscountFeature = (feature, features, billables, user) =>
  !!getDiscountFeatures(features, billables, user).find(
    (f) => f.feature.id === feature.feature.id
  );

export const getBudgetInitialValues = (project) => {
  if (!project || !project.is_one_to_one) {
    return undefined;
  }

  const awardValue = parseFloat(project.one_to_one_pre_negotiation_value);

  return {
    budget_option: awardValue ? "send_offer" : "request_quote",
    award_1_1: awardValue,
  };
};
